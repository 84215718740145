<template>
  <div class="order-card-container">
    <span class="order-header">Order Summary</span>
    <b-list-group class="list-group">
      <template v-if="!loadingItem">
        <template v-if="$options.components['items-cart-item']">
          <items-cart-item
            :cartItem="item"
            v-for="(item, index) in cartData"
            :key="index"
          />
        </template>
      </template>
      <template v-if="loadingItem">
        <b-card v-for="i in 3" :key="i" class="mb-3">
          <b-skeleton class="mb-2" animation="wave" width="85%"></b-skeleton>
          <b-skeleton class="mb-2" animation="wave" width="55%"></b-skeleton>
          <b-skeleton class="mb-2" animation="wave" width="70%"></b-skeleton>
          <b-skeleton class="mb-2" animation="wave" width="80%"></b-skeleton>
          <b-skeleton animation="wave" width="70%"></b-skeleton>
        </b-card>
      </template>
    </b-list-group>
  </div>
</template>
<script>
export default {
  name: "order-summary",
  props: {
    cartData: {
      default: () => [],
      type: Array,
    },
    loadingItem: {
      default: false,
      type: Boolean,
    },
  },
  methods: {},
  mounted() {},
};
</script>
<style scoped>
.order-left {
  left: 150px;
  z-index: 1;
}
.order-card-container {
  width: 80%;
}
.order-header {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 39px;
  color: #000000;
}
.list-group {
  margin-top: 15px;
  max-height: 540px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
@media (max-width: 768px) {
  .order-card-container {
    width: 100%;
  }
}
</style>

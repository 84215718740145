var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "order-card-container"
  }, [_c('span', {
    staticClass: "order-header"
  }, [_vm._v("Order Summary")]), _c('b-list-group', {
    staticClass: "list-group"
  }, [!_vm.loadingItem ? [_vm.$options.components['items-cart-item'] ? _vm._l(_vm.cartData, function (item, index) {
    return _c('items-cart-item', {
      key: index,
      attrs: {
        "cartItem": item
      }
    });
  }) : _vm._e()] : _vm._e(), _vm.loadingItem ? _vm._l(3, function (i) {
    return _c('b-card', {
      key: i,
      staticClass: "mb-3"
    }, [_c('b-skeleton', {
      staticClass: "mb-2",
      attrs: {
        "animation": "wave",
        "width": "85%"
      }
    }), _c('b-skeleton', {
      staticClass: "mb-2",
      attrs: {
        "animation": "wave",
        "width": "55%"
      }
    }), _c('b-skeleton', {
      staticClass: "mb-2",
      attrs: {
        "animation": "wave",
        "width": "70%"
      }
    }), _c('b-skeleton', {
      staticClass: "mb-2",
      attrs: {
        "animation": "wave",
        "width": "80%"
      }
    }), _c('b-skeleton', {
      attrs: {
        "animation": "wave",
        "width": "70%"
      }
    })], 1);
  }) : _vm._e()], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }